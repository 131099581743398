<template>
	<el-dialog :title="$store.state.teachlanguagedata.pleaseenterhomeworkinformation" :visible.sync="worksaveshow">
		<div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20">
			<span class="whiteSpaceNowrap">{{$store.state.teachlanguagedata.homeworktype}}</span>
			<el-select clearable filterable v-model="form.planKind" :placeholder="$store.state.teachlanguagedata.select" :disabled="CreateAiQuestionSetShow==2||CreateAiQuestionSetShow==3||CreateAiQuestionSetShow==4">
				<el-option v-for="item in planKinds" :key="item.valueName" :label="item.valueName"
					:value="item.valueName">
				</el-option>
			</el-select>
		</div>
		<div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20">
			<span class="whiteSpaceNowrap">{{$store.state.teachlanguagedata.homeworkname}}</span>
			<el-input style="width: 220px;" v-model="form.worksavename"></el-input>
		</div>
		<div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20" v-if="CreateAiQuestionSetShow!=2&&CreateAiQuestionSetShow!=4&&ClassQueryListData.list&&ClassQueryListData.list.length>0">
			<span>教室</span>
			<el-select clearable filterable v-model="form.roomId" placeholder="请选择教室"  class="width220" >
				<el-option v-for="item in ClassQueryListData.list"  :key="item.id" :label="item.name" :value="item.id">
				</el-option>
			</el-select>
		</div>
		<div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20" v-if="form.roomId">
		<!-- <div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20"> -->
			<span class="whiteSpaceNowrap">时间</span>
			<el-select clearable filterable v-model="queryTimeListDataCheck" placeholder="请选择时间" class="width220" value-key="id">
				<el-option v-for="item in queryTimeListData" :key="item.id"
					:label="`${item.code}:${item.beginTime}~${item.endTime}`" :value="item">
				</el-option>
			</el-select>
		</div>
		<div v-else>
			<div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20">
				<span class="whiteSpaceNowrap">{{$store.state.teachlanguagedata.startingtime}}</span>
				<el-date-picker v-model="form.beginTime" value-format="timestamp" :picker-options="starttime"
					type="datetime" :placeholder="$store.state.teachlanguagedata.Selectdateandtime">
				</el-date-picker>
			</div>
			<div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20">
				<span class="whiteSpaceNowrap">{{$store.state.teachlanguagedata.endtime}}</span>
				<el-date-picker v-model="form.endTime" :picker-options="endTime" value-format="timestamp" type="datetime"
					:placeholder="$store.state.teachlanguagedata.Selectdateandtime">
				</el-date-picker>
			</div>
		</div>
		<!-- 阶段隐藏掉 -->
		<!-- <div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20" v-if="stageIndex!=1||stageIndex!='1'">
			<span class="whiteSpaceNowrap">{{$store.state.teachlanguagedata.level}}</span>
			<el-select  clearable filterable v-model="form.stageKind" :placeholder="$store.state.teachlanguagedata.select" :disabled="stageKindShow">
				<el-option v-for="item in stageKinds" :key="item.valueName" :label="item.valueName"
					:value="item.valueName">
				</el-option>
			</el-select>
		</div> -->
		<div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20" v-if="CreateAiQuestionSetShow!=2">
			<span class="whiteSpaceNowrap">{{$store.state.teachlanguagedata.selectclass}}</span>
			<el-select clearable filterable v-model="form.selected" multiple value-key='id'>
				<el-option v-for="option in options" :label="option.name" :value="option" :key="option.id">
					{{ option.name }}
				</el-option>
			</el-select>
		</div>
		<div v-if="CreateAiQuestionSetShow==3||CreateAiQuestionSetShow==4">
			<div style="text-align: left;">
				<el-radio-group v-model="classType">
					<el-radio-button label="资源">资源</el-radio-button>
					<el-radio-button label="题集">题集</el-radio-button>
				</el-radio-group>
			</div>
			<div style="text-align: left;" v-if="classType=='资源'">
				<el-radio-group v-model="classTypes" size="small">
					<el-radio-button label="公共资源">公共资源</el-radio-button>
					<el-radio-button label="私有资源">私有资源</el-radio-button>
				</el-radio-group>
			</div>
			<div class="displayFlex alignItemsCenter justifyContentSpaceBetween marginBottom20">
				<span>{{classTypes}}</span>
				<el-select clearable filterable v-model="createAiQuestionSetData" placeholder="请选择资源" class="width220" value-key="id">
					<el-option v-for="item in resourceQueryListData.list" :key="item.id" :label="item.name"
						:value="item">
					</el-option>
				</el-select>
			</div>
		</div>
		<div slot="footer" class="dialog-footer">
			<el-button @click="worksaveshow = false">{{$store.state.teachlanguagedata.cancel}}</el-button>
			<el-button type="primary" @click="worksaveUpper()">{{$store.state.teachlanguagedata.confirm}}</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		createErrorBookSet
	} from '../../api/Collect.js'
	import {
		showLoading,
		hideLoading
	} from '../../utils/Loading'
	import {
		getZhangJie,
		taskSave
	} from '../../api/Source.js'
	import {
		queryListByDictValue,
		queryMyClassList,
		questionSetQueryList,
		resourceQueryList,
		classQueryList,
		queryTimeList,
		roomPlanSave
	} from '../../api/base.js'
	import {
		createAiQuestionSet,
	} from '../../api/Intelligence.js'
	export default {
		name: 'PublishJob',
		data() {
			return {
				classTypes:'公共资源',
				classType:'资源',// 教室
				planKinds: [], //作业类型
				stageKinds: [], //作业阶段
				options: [], // 班级列表
				tableData: [], //创建题目选择的题
				createAiQuestionSetData: [], // 创建题集参数
				chaptersId: null, // 章节Id
				resourceQueryListCheck:{},//选择资源
				queryTimeListDataCheck:{},//选择时间
				UserListStudentitem:{},// 用户相关信息
				ClassQueryListData:{
					currPage: 1,
					pageSize: 10000,
				},//教室列表
				resourceQueryListData: {
					currPage: 1,
					pageSize: 10000,
				},
				form: {
					stageKind:'课中',
				},
				stageKindShow:false,
				worksaveshow: false,
				formLabelWidth: '200px',
				defaultProps: {
					children: 'children',
					label: 'label'
				},
			}
		},
		watch:{
			queryTimeListDataCheck(val) {
				let time = new Date().toLocaleDateString();
				this.form.beginTime = new Date(time + ' ' + val.beginTime + ":00").getTime()
				this.form.endTime = new Date(time + ' ' + val.endTime + ":00").getTime()
			},
			classTypes(vla){
				this.ResourceQueryList()
			},
		},
		computed: {
			starttime() {
				let str = "",
					strtime = '';
				let startTimeDate = this.$moment(this.form.beginTime).format("YYYY-MM-DD");
				if (this.form.endTime) {
					let endTimeDate = this.$moment(this.form.endTime).format("YYYY-MM-DD");
					if (startTimeDate == endTimeDate) {
						str = this.$moment(this.form.endTime).format("HH:mm:ss");
					} else {
						str = "23:59:59"
					}
					strtime = "00:00:00 - " + str
				} else {
					strtime = "00:00:00 - 23:59:59"
				}
				return {
					disabledDate: (time) => {
						if (this.form.endTime) {
							return (
								time.getTime() > new Date(this.form.endTime).getTime() - 8.64e6 // 加- 8.64e6则表示包当天
							)
						}
					},
					selectableRange: strtime
				}
			},
			// 结束时间
			endTime() {
				let str = "",
					strtime = '';
				let endTimeDate = this.$moment(this.form.endTime).format("YYYY-MM-DD");
				let nowTimeDate = this.$moment(new Date().getTime()).format("YYYY-MM-DD");
				if (this.form.beginTime) {
					let startTimeDate = this.$moment(this.form.beginTime).format("YYYY-MM-DD");
					if (startTimeDate == endTimeDate) {
						if (endTimeDate == nowTimeDate) {
							str = this.$moment(new Date().getTime()).format("HH:mm:ss");
						} else {
							str = this.$moment(this.form.beginTime).format("HH:mm:ss");
						}
					} else if (nowTimeDate == endTimeDate) {
						str = this.$moment(new Date().getTime()).format("HH:mm:ss");
					} else {
						str = "00:00:00"
					}
				} else {
					if (nowTimeDate == endTimeDate) {
						str = this.$moment(new Date().getTime()).format("HH:mm:ss");
					} else {
						str = "00:00:00"
					}
				}
				strtime = str + " - 23:59:59"
				return {
					disabledDate: (time) => {
						if (this.form.beginTime) {
							return (
								time.getTime() < Date.now() - 8.64e7 || time.getTime() < new Date(this.form
									.beginTime)
								.getTime() - 8.64e7 // 加- 8.64e7则表示包当天
							)
						} else {
							return time.getTime() < Date.now() - 8.64e7
						}
					},
					selectableRange: strtime
				}
			},
		},
		mounted() {
			this.subjectId = JSON.parse(localStorage.getItem("teachsubjectId")) // 学科Id
			this.chaptersId = JSON.parse(localStorage.getItem('teachchapters'))[0]
		},
		props: [
			'CreateAiQuestionSetShow',
			'selectionArea',
			'clickFun',
			'stageIndex'
		],
		methods: {
			init() {
				this.chaptersId = this.$store.state.arrDeptId
				this.tableData = this.$parent.tableData
				this.querylistbydictvalue('plan_kind', 'planKinds')
				this.querylistbydictvalue('stage_kind', 'stageKinds')
				this.ResourceQueryList()
				this.getbanji()
				this.ClassQueryList()
				this.QueryTimeList()
				if (this.CreateAiQuestionSetShow == 1) {
					this.CreateAiQuestionSet()
				} else if (this.CreateAiQuestionSetShow == 2) {
					this.UserListStudentitem=this.$parent.UserListStudentitem
					this.kindName=this.$parent.kindName
					this.form.planKind=this.$parent.planKind
					this.createErrorbookset()
				}  else if (this.CreateAiQuestionSetShow == 3) {
					this.form.planKind=this.$parent.planKind
					this.worksaveshow = true
				}  else if (this.CreateAiQuestionSetShow == 4) {
					this.form.planKind=this.$parent.planKind
					this.worksaveshow = true
				} else {
					this.worksaveshow = true
				}
				if(this.form.planKind=='个性化'){
					this.form.stageKind='课后'
					this.stageKindShow=true
				}
			},
			// 学校下时间
			QueryTimeList() {
				queryTimeList({
					"fromId": JSON.parse(localStorage.getItem('teachschoolID'))
				}).then(res => {
					this.queryTimeListData = res.data.result
				})
			},
			// 教室列表
			ClassQueryList() {
				let data = {
					"pageSize": this.ClassQueryListData.pageSize,
					"currPage": this.ClassQueryListData.currPage,
					"fromId": JSON.parse(localStorage.getItem('teachschoolID')),
					"classKind": "room"
				}
				classQueryList(data).then(res => {
					this.ClassQueryListData = res.data.result
				})
			},
			// 用户下的班级
			getbanji() {
				let data = {
					'userToken': JSON.parse(localStorage.getItem("teachuserToken")),
				}
				queryMyClassList(data).then(response => {
					this.options = response.data.result
				})
			},
			// 字典 n 字典类型 value 字典名字
			querylistbydictvalue(n, value) {
				queryListByDictValue('dictValue=' + n).then(res => {
					this[value] = res.data.result;
				})
			},
			// 创建题集学生
			createErrorbookset() {
				let data = {
					questionOutList: this.tableData,
					userId: this.UserListStudentitem.id,
					userName: this.UserListStudentitem.name,
					subjectId:this.subjectId,
					kindName: this.kindName
				}
				createErrorBookSet(data).then(res => {
					this.worksaveshow = true
					this.createAiQuestionSetData = res.data.result
				})
			},
			// 创建题集班级
			CreateAiQuestionSet() {
				let data = {
					position: JSON.parse(localStorage.getItem("teachsubjects")).filter((item) => {
						return item.id == this.subjectId
					})[0].position,
					questionOutList: this.tableData,
					subjectId: this.subjectId,
					textbookId: JSON.parse(localStorage.getItem("textbookId")),
					catalogSetRelationList: [{
						relationId: this.chaptersId,
						relationKind: "chapter",
					}],
				}
				showLoading()
				createAiQuestionSet(data).then((res) => {
					hideLoading()
					this.createAiQuestionSetData = res.data.result
					this.worksaveshow = true
				}).catch(res => {
					hideLoading()
				})
			},
			// 创建作业上一步
			worksaveUpper(){
				if(this.form.roomId){
					this.RoomPlanSave()
				}else{
					this.worksave()
				}
			},
			//创建智慧教室作业
			RoomPlanSave(){
				if(!this.chaptersId){
					this.$message({
						message: "请选择左侧教学章节",
						type: 'warning',
						duration: 3000
					})
				}
				let data={
					"name": this.form.worksavename,
					"subjectId": this.subjectId,
					"planKind": this.form.planKind,
					"stageKind": this.form.stageKind,
					"resourceRelationList": [{
						"relationId": this.createAiQuestionSetData.id,
						"relationKind": "questionSet",
						"relationName": this.createAiQuestionSetData.name
					}, {
						"relationId": this.chaptersId,
						"relationKind": "chapter"
					}],
					"userRelationList": [],
					
					"fromId": JSON.parse(localStorage.getItem('teachschoolID')),
					"beginTime": this.form.beginTime,
					"endTime": this.form.endTime,
					"roomId":this.form.roomId,
					"num":this.queryTimeListDataCheck.number,
				}
				this.form.selected.forEach(item => {
					let userRelation = {
						"relationId": null,
						"relationKind": "class",
						"relationName": null
					}
					userRelation.relationId = item.id
					userRelation.relationName = item.name
					data.userRelationList.push(userRelation)
				})
				roomPlanSave(data).then(res=>{
					this.worksaveshow = false
					location.reload();
				})
			},
			// 创建作业
			worksave() {
				let that = this
				if(!this.chaptersId){
				
				this.$message({
					message: "请选择左侧教学章节",
					type: 'warning',
					duration: 3000
				})
				this.worksaveshow = false
				return
			}
				let data = {
					"beginTime": this.form.beginTime,
					"endTime": this.form.endTime,
					
					"name": this.form.worksavename,
					"subjectId": this.subjectId,
					"planKind": this.form.planKind,
					"stageKind": this.form.stageKind,
					"resourceRelationList": [{
						"relationId": this.createAiQuestionSetData.id,
						"relationKind": "questionSet",
						"relationName": this.createAiQuestionSetData.name
					}, {
						"relationId": this.chaptersId,
						"relationKind": "chapter"
					}],
					"userRelationList": []
				}

				
				if(this.CreateAiQuestionSetShow == 2){
					data.userRelationList[0]={
						relationId: this.UserListStudentitem.id,
						relationKind: "student",
						relationName: this.UserListStudentitem.name
					}
				}else{
					this.form.selected.forEach(item => {
						let userRelation = {
							"relationId": null,
							"relationKind": "class",
							"relationName": null
						}
						userRelation.relationId = item.id
						userRelation.relationName = item.name
						data.userRelationList.push(userRelation)
					})
				}
				console.log(data);
				
				taskSave(data).then((res) => {
					
					this.worksaveshow = false
					this.$message("发布成功")
					window.location.reload(); 
					// that.$parent.init()
				})
			},
			// 资源
			ResourceQueryList() {
				let data = {
					"currPage": this.resourceQueryListData.currPage,
					"pageSize": this.resourceQueryListData.pageSize,
					"fromId": this.classTypes=='公共资源'?10001:JSON.parse(localStorage.getItem('teachschoolID')),
				}
				resourceQueryList(data).then(res => {
					this.resourceQueryListData = res.data.result
					if(res.data.result.list&&res.data.result.list.length>0){
						this.createAiQuestionSetData=res.data.result.list[0]
					}
				})
			},
			// 题集
			QuestionSetQueryList() {
				let data = {
					"currPage": this.resourceQueryListData.currPage,
					"pageSize": this.resourceQueryListData.pageSize,
					"corpId": JSON.parse(localStorage.getItem('teachschoolID')),
					relationId: JSON.parse(localStorage.getItem('teachchapters'))[0],
					relationKind: "chapter",
				}
				questionSetQueryList(data).then(res => {
					this.resourceQueryListData = res.data.result
				})
			},

		}
	}
</script>
<style>
</style>
