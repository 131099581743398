<template>
	<div>
		<div class="title" v-for="(tableDatas,index) in tableData">
			<div class="title_top">
				<div>
					<span>{{$store.state.teachlanguagedata.questiontype}}:{{tableDatas.featureTypeName}}</span>
					<span v-if="tableDatas.diffcult" style="margin-left: 20px;">{{$store.state.teachlanguagedata.difficulty}}：<el-rate v-model="tableDatas.diffcult"
							disabled show-score text-color="#ff9900"></el-rate></span>
				</div>
				<div>
					<el-button @click="deltetableData(index)">{{$store.state.teachlanguagedata.delete}}</el-button>
					<el-button style="background-color: #fdc3c3;" @click="errortableData(tableDatas.id,index)">{{$store.state.teachlanguagedata.errorcorrection}}</el-button>
				</div>
			</div>
			<div class="title_con">
				<div class="title_con_content" style="margin-top: 20px;">
					<span v-html="tableDatas.content"></span>
				</div>
				<div class="title_con_con" style="margin-top: 20px;">
					{{$store.state.teachlanguagedata.answeranalysis}}:
					<span style="background: #f3f5f9;padding: 5px 10px;" @click="isExpandClick(index)">}</span>
					<section style="background: #f3f5f9;padding: 5px 10px;margin-top: 20px;overflow: auto;"
						:style="{'display':index==isExpandindex ? '':'none'}" class="question-answer">
						<div style="margin-top: 10px;">
							<span class="question-label">{{$store.state.teachlanguagedata.Referenceanswer}}：</span>
							<span class="question-text" v-for="item in tableDatas.answerList"><span
									v-html="item.value"></span></span>
						</div>
						<div>
							<span class="question-label" style="display:flex">
								<span style="white-space: nowrap;">{{$store.state.teachlanguagedata.Topicanalysis}}：</span>
								<span v-html="tableDatas.analysis"></span>
							</span>
						</div>
					</section>
				</div>
				<div class="title_con_foot" style="margin-top: 20px;" v-if="tableDatas.catalogList">
					{{$store.state.teachlanguagedata.knowledgepoints}}:<span v-for="item in tableDatas.catalogList"><span v-html="item.name"></span></span>
				</div>
			</div>
		</div>
		<div class="zhanwushujiu" v-if="tableData.length<1">
			<img src="../assets/img/zhanwushuju.png" >
		</div>
	</div>
</template>
<script>
	import {
		errordelete
	} from '../api/Intelligence.js'
	export default {
		name: 'App',
		data() {
			return {
				Collectshow:'',
				isExpandindex: 'n',
			}
		},
		props: [
			'tableData'
		],
		mounted() {
			this.Collectshow=window.location.hash
			this.Mathqunati()
		},
		methods: {
			errortableData(id,index){
				this.tableData.splice(index,1)
				errordelete({id}).then((res)=>{
				})
			},
			deltetableData(index){
				this.tableData.splice(index,1)
				this.Mathqunati()
			},
			isExpandClick(index) {
				if (this.isExpandindex == index) {
					this.isExpandindex = 'n'
				} else {
					this.isExpandindex = index
				}
			},
			Mathqunati() {
				this.$nextTick(function() { //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
					if (this.commonsVariable.isMathjaxConfig) { //判断是否初始配置，若无则配置。
						this.commonsVariable.initMathjaxConfig();
					}
					this.commonsVariable.MathQueue("title");
				})
			}
		}
	}
</script>
<style scoped="scoped">
	.zhanwushujiu{
		width: 100%;
		height: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	.title {
		border: 1px solid #d8deea;
		margin-top: 20px;
		padding-bottom: 20px;
	}

	.title_con_content>>>img {
		max-width: 100%;
	}

	.title_con_content {
		color: #13264d;
		font-weight: 600;
		font-size: 18px;
		line-height: 27px;
	}

	.title_con_con {
		margin-left: 20px;
	}

	.title_con_foot span {
		height: 28px;
		line-height: 28px;
		padding: 8px;
		color: #6e7c94;
		background-color: #edeff2;
		margin-left: 8px;
		border-radius: 2px;
	}

	.title_top {
		height: 56px;
		background-color: #f3f5f9;
		padding: 0 20px;
		line-height: 56px;
		display: flex;
		justify-content: space-between;
	}
	 .title:nth-of-type(1) {
		 margin-top: 0;
	 }
	.title_con {
		padding: 12px 20px 0 20px;
	}
</style>
