import request from '@/utils/requesthttps'
import request2 from '@/utils/request2'
export function queryAiListByCatalogTactics(data){
    return request({
        url:'/resourcePlant-web/api/console/questionAi/queryAiListByCatalogTactics',
        method:'post',
        data
    })
}
export function queryRelationList(data){
    return request({
        url:'/resourcePlant-web/api/console/catalog/queryMuiltRelationList',
        method:'post',
        data
    })
}
export function queryAllTypeList(data){
    return request2({
        url:'/resourcePlant-web/api/console/question/queryAllTypeList',
        method:'post',
        data
    })
}
export function queryListByDictValue(data){
    return request2({
        url:'/resourcePlant-web/api/console/question/queryListByDictValue',
        method:'post',
        data
    })
}
export function aiQuestionPdf(data){
    return request({
        url:'/resourcePlant-web/api/console/questionAi/aiQuestionPdf',
        method:'post',
        data,
		responseType: "blob"
    })
}
export function errorBookQuestionPdf(data){
    return request({
        url:'/resourcePlant-web/api/console/errorBook/errorBookQuestionPdf',
        method:'post',
        data,
		responseType: "blob"
    })
}
export function errordelete(data){
    return request({
        url:'/resourcePlant-web/api/console/question/delete',
        method:'post',
        data,
		responseType: "blob"
    })
}
export function createAiQuestionSet(data){
    return request({
        url:'/resourcePlant-web/api/console/questionAi/createAiQuestionSet',
        method:'post',
        data,
    })
}
